import React from 'react';
import { useStaticQuery, graphql} from 'gatsby';

import AniLink from "gatsby-plugin-transition-link/AniLink";

		// allWpConference(sort: {order: DESC, fields: conferencesExtra___year}) {
const newConferences_query = graphql`
	query NewConferencesQuery {
		wpgraphql {
			conferences(last: 6) {
			  	nodes {
					title
					menuOrder
					conferencesExtra {
						conferenceName
						location
						month
						year
						day
					}
			  	}
			}
		}
	}
`;

const NewConferences = () => {
	const data = useStaticQuery(newConferences_query);

	const conferences = data.wpgraphql.conferences.nodes;

	const today = new Date();

	const filteredNewconferences = conferences.filter((conf) => {
		const date = [
			conf.conferencesExtra.year,
			conf.conferencesExtra.month === null ? 1 : conf.conferencesExtra.month-1,
			conf.conferencesExtra.day === null ? 1 : conf.conferencesExtra.day
		];
		let confDate = new Date(date);
		return confDate >= today
	})

	let filteredSortedNewconferences;
	if (filteredNewconferences.length){
		filteredSortedNewconferences = filteredNewconferences.sort((a, b) => {
			const date1 = [
				a.conferencesExtra.year,
				a.conferencesExtra.month === null ? 1 : a.conferencesExtra.month-1,
				a.conferencesExtra.day === null ? 1 : a.conferencesExtra.day
			];
			const date2 = [
				b.conferencesExtra.year,
				b.conferencesExtra.month === null ? 1 : b.conferencesExtra.month-1,
				b.conferencesExtra.day === null ? 1 : b.conferencesExtra.day
			];
			let confDate1 = new Date(date1);
			let confDate2 = new Date(date2);
			return (confDate1 >= confDate2 ? 1 : -1)
		})
	} else {
		filteredSortedNewconferences = null;
	}


	const sortedLatestConferences = conferences.sort((a, b) => {

		return (a.menuOrder > b.menuOrder ? 1 : -1)
	})

	return (
		<section className="content" id="newConferences">
			<h1>Conferences</h1>
			<h2>Conferences with m1nd-set presentations</h2>
			<div id="newConferencesListWrapper">
				<div id="newConferencesList" className={`${filteredSortedNewconferences === null ? "empty" : ""}`}>
				{/* {filteredSortedNewconferences !== null ?
					filteredSortedNewconferences.map((conf, i) => {
						if (i >= 6) return false;
						return (
							<div key={i} className="conference">
								<h3>{conf.conferencesExtra.conferenceName}</h3>
								<p>
									{conf.conferencesExtra.location !== null ? `${conf.conferencesExtra.location} - ` : ""}
									{conf.conferencesExtra.day === null ? "" : `${conf.conferencesExtra.day}.`}
									{conf.conferencesExtra.month === null ? "" : `${conf.conferencesExtra.month}.`}
									{conf.conferencesExtra.year}
								</p>
							</div>
						)
					})
				:
					<> */}
						<h2>Upcoming and Recent conferences:</h2>
						<div id="latestConferences">
							{sortedLatestConferences.map((conf, i) => {
								if (i >= 6) return false;
								return (
									<div className="conference" key={i}>
										<h3>{conf.conferencesExtra.conferenceName}</h3>
										<p>
											{conf.conferencesExtra.location !== null ? `${conf.conferencesExtra.location} - ` : ""}
											{conf.conferencesExtra.day === null ? "" : `${conf.conferencesExtra.day} `}
											{conf.conferencesExtra.month === null ? "" : `${conf.conferencesExtra.month} `}
											{conf.conferencesExtra.year}
										</p>
									</div>
								)
							})}
						</div>
					{/* </>
				} */}
				</div>
				<AniLink
					to="/about-us#pastConferences"
					className="button-general"
					cover bg="#EE82BF"
					direction="right"
					duration={0.8}
				>
					Other conferences
				</AniLink>
			</div>
		</section>
	);
};

export default NewConferences;