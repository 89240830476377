import React, { useEffect } from 'react'

import SeoWrapper from './seoWrapper'

import HeaderHome from "./headerHome"
import Footer from "./footer"

import '../misc/styles.scss';

const LayoutHome = ({ children }) => {

	useEffect(() => {
		document.querySelector('.container').classList.remove('loading');
		// return () => {
		// 	document.querySelector('.container').classList.add('loading');
		// }
	}, []);

	return (
		<SeoWrapper>
			<div className="container loading">
				<HeaderHome />
				<main>
					{children}
				</main>
				<Footer/>
			</div>
		</SeoWrapper>
	)
}

export default LayoutHome