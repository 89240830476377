import React from 'react';
import { useStaticQuery, graphql} from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import "swiper/css/autoplay"
import "swiper/css/lazy"

import { Autoplay, Lazy } from 'swiper';

const partners_query = graphql`
	query PartnersQuery {
		allWpPartner {
			nodes {
				title
				featuredImage {
					node {
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									placeholder: NONE
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		}
	}
`;

const Partners = () => {
	const data = useStaticQuery(partners_query);

	const partners = data.allWpPartner.nodes;

	return (
		<div id="partners">
			<section className="content">
				<h1>Partners</h1>
				<Swiper
						// {...paramsNormal}
						// navigation={true}
						modules={[Autoplay, Lazy]}
						spaceBetween={30}
						centeredSlides={true}
						autoplay={{
							"delay": 800,
							"disableOnInteraction": false
						}}
						className="clientsSwiper"
						loop={true}
						// slidesPerView={'auto'}
						slidesPerView={8}
						breakpoints={{
							0: {
							  slidesPerView: 4,
							  spaceBetween: 30
							},
							768: {
							  slidesPerView: 8,
							  spaceBetween: 30
							}
						}}
					>
					{partners.map((partner, i) => {
						return (
							<SwiperSlide key={i}>
								{partner.featuredImage !== null &&
									<GatsbyImage image={getImage(partner.featuredImage.node.localFile)} alt={partner.title} title={partner.title} />
								}
							</SwiperSlide>
						)
					})}
				</Swiper>
			</section>
		</div>
	);
};

export default Partners;