import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SingleNews from './singleNews'

import AniLink from "gatsby-plugin-transition-link/AniLink";

const latestNews_query = graphql`
	query LatestNewsQuery {
		allWpNews(limit: 3, sort: {order: DESC, fields: date}){
			nodes {
				newsextra {
					date
					keyWords
					link1 {
						link1Label
						link1Url
					}
					link2 {
						link2Label
						link2Url
					}
					link3 {
						link3Label
						link3Url
					}
					link4 {
						link4Label
						link4Url
					}
					link5 {
						link5Label
						link5Url
					}
				}
				title
				content
				featuredImage {
					node {
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									placeholder: NONE
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		}
	}
`;

const LatestNews = () => {
	const data = useStaticQuery(latestNews_query);
	const latestNews = data.allWpNews.nodes;

	const sortedNews = latestNews.sort((a, b) => {
		let newsdate1 = new Date(a.newsextra.date);
		let newsdate2 = new Date(b.newsextra.date);
		return (newsdate2 < newsdate1 ? 1 : 0)
	})

	return (
		<section id="latestNews" className="content">
			<h1>m1nd-set in the media</h1>
			<ul>
				{sortedNews.map((newsItem, i) => {
					return (
						<SingleNews key={i} props={{ newsItem }}/>
					)
				})}
			</ul>
			<AniLink
				to="/news"
				className="button-general small"
				cover bg="#EE82BF"
				direction="right"
				duration={0.8}
			>
				More news
			</AniLink>
		</section>
	);
}

export default LatestNews;