import * as React from "react"
// import { StaticImage } from 'gatsby-plugin-image'
// import AniLink from "gatsby-plugin-transition-link/AniLink";

import LayoutHome from '../components/layoutHome'
import Introduction from '../components/introduction'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Clients from '../components/clients'
import Partners from '../components/partners'
import NewConferences from '../components/newConferences'
import LatestNews from '../components/latestNews'
import Subscribe from '../components/subscribe'

const IndexPage = ({ data }) => {

	const page = data.wpMainPage;

	return (
		<LayoutHome>
			<Seo post={page} />
			<section className="contentWrapper">
				{/* <StaticImage
					alt="Clifford, a reddish-brown pitbull, posing on a couch and looking stoically at the camera"
					src="../images/doggo.webp"
				/> */}
				<Introduction />
				<Clients />
				<NewConferences />
				<Partners />
				<LatestNews />
				<Subscribe/>
			</section>
		</LayoutHome>
	)
}

export default IndexPage

export const query = graphql`
	query HomePageQuery {
		wpMainPage(databaseId: {eq: 83}) {
			content
			nodeType
			title
			uri
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
	}
`;