import React from "react"
import Menu from './menu'

import { graphql, useStaticQuery } from 'gatsby'

import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import AniLink from "gatsby-plugin-transition-link/AniLink";

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';

import { Autoplay, Lazy } from 'swiper';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

const header_image_query = graphql`
query headerImageQuery {
	allWpHeaderImage(sort: {fields: menuOrder}) {
		nodes {
			title
			menuOrder
			featuredImage {
					node {
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									placeholder: NONE
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		}
	}
`;

const Header = () => {
	const data = useStaticQuery(header_image_query);

	const images = data.allWpHeaderImage.nodes;
	// sort images by menu order
	const sortedImages = images.sort((a, b) => {
		return ((a.menuOrder+1) < (b.menuOrder+1) ? -1 : 0)
	})

  	return (
		<header>
			<div id="topBar">
				<section className="content">
					<div id="logoContainer">
						<div id="logoWrapper">
							<StaticImage src="../images/logo_new_white_small.png" alt="logo" id="headerLogo"/>
						</div>
					</div>
					<div id="topContact">
						<a href="mailto:info@m1nd-set.com">info@m1nd-set.com</a>
						<a href="https://www.linkedin.com/company/m1nd-set/" target="_blank" rel="noreferrer" id="linkedinLink" aria-label="Linkedin link">
							<FontAwesomeIcon icon={faLinkedin} size="1x" width="13" />
						</a>
					</div>
				</section>
			</div>
			<div id="landingSection">
				<div id="landingInner">
					<section className="content">
						<Menu id="mainMenu"/>
					</section>
					<section className="contentRight">
						<div id="landingContentWrapper">
							<div className="columnLeft">
								<span className="small">HOME</span>
								<span className="homeTitle">Research<br />Beyond Borders</span>
								<p>We are specialists in travel related marketing intelligence and consulting</p>
								<AniLink
									className="button-general"
									to="/about-us"
									cover bg="#EE82BF"
									direction="right"
									duration={0.8}
								>
									More about us
								</AniLink>
							</div>
							<div className="columnRight">
								<div id="headerCarousel">

									{sortedImages.length === 1 ?
										<GatsbyImage image={getImage(sortedImages.featuredImage.node.localFile)} alt="header image" />
									:
										<Swiper
											// {...paramsNormal}
											// navigation={true}
											modules={[Autoplay, Lazy]}
											autoplay={{
												"delay": 5000,
												"disableOnInteraction": false,
											}}
											// className="clientsSwiper"
											loop={true}
											height="620"
											// slidesPerView={'auto'}
										>
											{sortedImages.map((image, i) => (
												<SwiperSlide key={i}>
													{image.featuredImage !== null	?
														<GatsbyImage image={getImage(image.featuredImage.node.localFile)} alt={image.title} />
													: ''}
												</SwiperSlide>
											))}
										</Swiper>
									}
								</div>
							</div>
						</div>

					</section>

				</div>
			</div>

		</header>
    )
}

export default Header
