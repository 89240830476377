import React from 'react';
import { useStaticQuery, graphql} from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import _ from 'lodash'

// import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import "swiper/css/navigation"
import "swiper/css/autoplay"
import "swiper/css/lazy"

import { Autoplay, Lazy } from 'swiper';

// import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
// import { Swiper, Navigation, Lazy, Autoplay } from 'swiper';


const clients_query = graphql`
	query ClientsQuery {
		allWpClient(limit: 500, sort: {order: ASC, fields: title}) {
			nodes {
				title
				featuredImage {
					node {
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									placeholder: NONE
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		}
	}
`;

const Clients = () => {
	const data = useStaticQuery(clients_query);

	const clients = data.allWpClient.nodes;

	const clientsChunks = _.chunk(clients, _.ceil(clients.length/3));

	// const swiperRef = React.useRef(null);
	return (
		<section id="clients">
			<div id="clientsHeader">
				<StaticImage src="../images/clients-pic.jpg" alt="sky"/>
				<h1>Our <span>{clients.length}</span> clients</h1>
			</div>
			<section className="content">

				{clientsChunks.map((clientChunk, chunkIndex) => {
					return (

						<Swiper
							key={chunkIndex}
							// {...paramsNormal}
							// navigation={true}
							modules={[Autoplay, Lazy]}
							spaceBetween={30}
							centeredSlides={true}
							autoplay={{
								"delay": 800,
								"disableOnInteraction": false,
								"reverseDirection": chunkIndex % 2 ? true : false,
							}}
							// speed={1000}
							className="clientsSwiper"
							loop={true}
							// slidesPerView={'auto'}
							slidesPerView={8}
							breakpoints={{
								0: {
								  slidesPerView: 4,
								  spaceBetween: 30
								},
								768: {
								  slidesPerView: 8,
								  spaceBetween: 30
								}
							}}
						>
							{clientChunk.map((client, i) => {
								return (
									<SwiperSlide key={i}>
										{client.featuredImage !== null	?
											<GatsbyImage image={getImage(client.featuredImage.node.localFile)} alt={client.title} />
											// <StaticImage src={client.featuredImage.node.sourceUrl} alt={client.title}/>
										: ''}
									</SwiperSlide>
								)
							})}
						</Swiper>
					)
				})}


			</section>
		</section>
	);
};

export default Clients;