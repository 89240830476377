import React from 'react';
import { useStaticQuery, graphql} from 'gatsby';

import { StaticImage } from "gatsby-plugin-image"

// import icon1 from '../images/data-analysis.svg'
// import icon2 from '../images/airport.svg'
// import icon3 from '../images/interview.svg'
// import icon4 from '../images/rating.svg'

const query = graphql`
	query introQuery {
		allWpComponent(
			filter: {databaseId: {in: [80, 237]}}
			sort: {fields: databaseId, order: ASC}
		) {
			nodes {
				title
				content
				introSectionsExtra {
					box1Content
					box2Content
					box3Content
					box4Content
				}
			}
		}
	}
`;

const Introduction = () => {
	const data = useStaticQuery(query);

	const intro = data.allWpComponent.nodes[0];
	const introSectors = data.allWpComponent.nodes[1].introSectionsExtra;

	return (
		<div id="introduction">
			<section className="content">
				<h1>The worldwide<br />leader
					<p>in travel market research</p>
				</h1>
				<div id="introContent" dangerouslySetInnerHTML={{ __html: intro.content }}></div>
				<div id="introSectors" >
					<ul>
						<li>
							<div className="iconWrapper">
								<StaticImage src="../images/airport.svg" alt="icon1" />
							</div>
							<div dangerouslySetInnerHTML={{ __html: introSectors.box1Content }}/>
						</li>
						<li>
							<div className="iconWrapper">
								<StaticImage src="../images/data-analysis.svg" alt="icon2" />
							</div>
							<div dangerouslySetInnerHTML={{ __html: introSectors.box2Content }}/>
						</li>
						<li>
							<div className="iconWrapper">
								<StaticImage src="../images/interview.svg" alt="icon3" />
							</div>
							<div dangerouslySetInnerHTML={{ __html: introSectors.box3Content }}/>
						</li>
						<li>
							<div className="iconWrapper">
								<StaticImage src="../images/rating.svg" alt="icon4" />
							</div>
							<div dangerouslySetInnerHTML={{ __html: introSectors.box4Content }}/>
						</li>
					</ul>
				</div>
			</section>
		</div>
	);
};

export default Introduction;